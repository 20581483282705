@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.capital {
  background-image: url('../build/img/cover_pic_home.webp');
  position: relative;
  height: 92vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.capital::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(253, 253, 253, 0.521);
}

.flag {
  background-image: url('../build/img/cover_pic_about.webp');
  position: relative;
  /* height: 100vh; */
  width: 100%;
  
  /* align-items: center; */
  /* justify-content: center; */
  background-size: cover;
}

.flag::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(253, 253, 253, 0.692);
}

.activeLink a.active {
  color: #C8B591;
}
.activeLink a.inactive {
  color: Black;

}
